<template>
  <div class="full-height" v-if="event && questions">
    <v-container class="full-height" id="recommended-activities">
      <v-row class="full-height" no-gutters>
        <v-col class="full-height">
          <div class="content">
            <h1 class="title">{{ $t('activities.title') }}</h1>

            <div class="questions__wrapper">
              <v-carousel
                height="auto"
                :show-arrows="false"
                :hide-delimiters="true"
                light
                v-model="carouselIndex"
              >
                <v-carousel-item
                  class="questions"
                  v-for="(question, index) in questions"
                  :key="index"
                >
                  <question
                    class="question"
                    :question="question"
                    :index="index"
                    :isExhibitor="isExhibitor"
                    @update-answer="updateAnswer"
                  />
                </v-carousel-item>
              </v-carousel>

              <div class="controls">
                <v-btn
                  v-if="!hasNext"
                  :disabled="!isAnswered(currentQuestion)"
                  x-large
                  :to="{ name: 'ActivitiesConfirmation' }"
                  color="primary"
                  outlined
                >
                  {{ $t('activities.finish') }}
                </v-btn>
              </div>
            </div>

            <div class="secondary-controls">
              <div class="secondary-controls__prev">
                <v-btn v-if="hasPrevious" text @click="carouselIndex--">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </div>

              <div class="secondary-controls__label">
                {{
                  $t('activities.pagination', {
                    count: carouselIndex + 1,
                    total: questions.length,
                  })
                }}
              </div>

              <div class="secondary-controls__next">
                <v-btn v-if="hasNext && isAnswered(currentQuestion)" text @click="carouselIndex++">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>

              <div class="secondary-controls__progress">
                <v-progress-linear v-model="progress" color="primary" height="10" />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import store from '@/stores';

import QuestionnaireUtils from '@/helpers/questionnaire/questionnaire.helper';
import { TicketType } from '@/models/ticketing/ticket-type.model';

import {
  ACTIVITY_MODULE,
  ANSWER_ACTIVITY,
  GET_ACTIVITY_QUESTIONS,
} from '@/stores/umanize-app/actions/activity/activity.actions';

import Question from '@/components/question/Question.vue';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'Activities',
  components: {
    Question,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch(`${ACTIVITY_MODULE}/${GET_ACTIVITY_QUESTIONS}`, to.params.eventId);
    const activityQuestions = store.getters[`${ACTIVITY_MODULE}/questions`];

    if (QuestionnaireUtils.hasAnsweredLastQuestion(activityQuestions)) {
      next({
        name: 'ActivitiesConfirmation',
        params: { ...to.params },
      });
    } else {
      next();
    }
  },
  data: () => ({
    carouselIndex: 0,
  }),
  computed: {
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(ACTIVITY_MODULE, ['questions']),
    hasPrevious() {
      return this.carouselIndex > 0;
    },
    hasNext() {
      return this.carouselIndex < this.questions.length - 1;
    },
    progress() {
      return (((this.carouselIndex || 0) + 1) * 100) / this.questions.length;
    },
    isExhibitor() {
      return this.event?.ticket?.type === TicketType.exhibitor;
    },
    currentQuestion() {
      return this.questions[this.carouselIndex];
    },
  },
  methods: {
    ...mapActions(ACTIVITY_MODULE, [GET_ACTIVITY_QUESTIONS, ANSWER_ACTIVITY]),
    async updateAnswer(answer) {
      const { eventId } = this.$route.params;
      const questionId = this.questions[this.carouselIndex]?.id;
      const answerId = answer;

      await this[ANSWER_ACTIVITY]({
        eventId,
        questionId,
        answerId,
      });

      if (!this.error && this.hasNext) {
        this.carouselIndex += 1;
      }
    },
    isAnswered(currentQuestion) {
      return (
        this.questions.find((question) => question.id === currentQuestion?.id)?.currentAnswerIds
          ?.length > 0
      );
    },
    setCurrentCarouselIndex() {
      this.carouselIndex = QuestionnaireUtils.getCurrentQuestionIndex(this.questions);
    },
  },
  mounted() {
    this.setCurrentCarouselIndex();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';

#recommended-activities {
  height: 100%;
}

.full-height {
  height: 100%;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.title {
  flex: 1;
}

.questions {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 10;

    height: 18rem;
  }
}

.question {
  width: 100%;
}

.controls {
  display: flex;
  justify-content: center;
}

.secondary-controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;

  margin: auto;

  &__prev {
    display: flex;
    justify-content: center;
    flex: 1;
  }

  &__label {
    display: flex;
    justify-content: center;
    flex: 3;
  }

  &__next {
    display: flex;
    justify-content: center;
    flex: 1;
  }

  &__progress {
    padding: 1rem;
    width: 100%;
  }
}

@include breakpoint(small) {
  .questions {
    max-width: 40rem;
    margin: auto;
    text-align: justify;
  }

  .secondary-controls {
    width: 20rem;
  }
}
</style>
