<template>
  <div class="activities-confirmation">
    <div class="confirmation">
      <h1>{{ $t('activities.thanks') }}</h1>

      <div class="text" v-if="isEventOpened">
        <v-btn x-large to="results" color="primary" outlined>
          {{ $t('activities.results') }}
        </v-btn>
      </div>

      <div v-else class="text">
        {{
          $t('activities.backLater', {
            date: $d(Date.parse(event.startDate), 'short'),
          })
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DateUtil from '@/helpers/date/date.helper';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';

export default {
  name: 'ActivitiesConfirmation',
  computed: {
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    isEventOpened() {
      return DateUtil.isNowOrBefore(this.event.startDate);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';

.activities-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  height: 100%;
}

.confirmation {
  width: 30rem;
}

.text {
  padding: 1rem;
  font-size: $large-font-size;
}
</style>
