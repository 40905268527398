<template>
  <div class="question mb-4">
    <div class="question__number ml-1">{{ index + 1 }}</div>
    <div class="question__content">
      <div class="question__text">
        {{ getUserCorrespondingQuestion }}
      </div>

      <div class="question__answers flex-wrap">
        <v-select
          outlined
          color="primary"
          :items="question.answers"
          item-text="answer"
          item-value="id"
          v-model="answerIds"
          :multiple="isMultiple"
          @change="!isMultiple ? updateSingleAnswer($event) : null"
          class="question__answers__select"
          hide-details
        />

        <v-btn
          class="ml-4"
          color="primary"
          large
          :disabled="!hasAnswered"
          @click="updateMultipleAnswers"
          v-if="isMultiple"
        >
          <span v-if="isLast">
            {{ $t('smart-matching.finish') }}
          </span>

          <span v-else>
            {{ $t('smart-matching.validate') }}
          </span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Question',
  props: {
    question: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isExhibitor: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      answerIds: this.question.currentAnswerIds || [],
    };
  },
  computed: {
    getUserCorrespondingQuestion() {
      if (this.question.questions) {
        return this.isExhibitor
          ? this.question.questions.exhibitor
          : this.question.questions.visitor;
      }

      return this.question.question;
    },
    hasAnswered() {
      return this.answerIds.length > 0;
    },
  },
  methods: {
    updateSingleAnswer(answerId) {
      this.$emit('update-answer', answerId);
    },
    updateMultipleAnswers() {
      this.$emit('update-answers', this.answerIds);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';

.question {
  width: 100%;
  display: flex;

  &__number {
    width: 2rem;

    font-size: $x-large-font-size;
    font-weight: $bold;
    color: var(--v-gray-lighten1);
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 1rem;
  }

  &__content {
    width: 100%;

    display: flex;
    flex-direction: column;
    font-size: $large-font-size;
  }

  &__answers {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @include breakpoint(small) {
    width: 5rem;
  }

  .question__answers__select {
    max-width: 80%;
  }
}
</style>
