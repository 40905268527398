<template>
  <v-sheet>
    <div v-for="(activity, index) in activities" :key="index">
      <conference-detail
        v-if="activity.type === 'conference'"
        :key="index"
        :activity="activity"
        :event="event"
        :is-on-user-agenda="hasConferenceInAgenda(activity)"
        :is-sending="isSending"
        :theme="getThemeColor(index)"
        :now="now"
        :is-admin="userIsAdmin"
        @add-to-agenda="addConferenceToAgenda"
        @remove-from-agenda="removeConferenceFromAgenda"
        @participate="participate"
      />
      <discussion-group-detail
        v-if="activity.type === 'discussionGroup'"
        :discussion-group="activity"
        :event="event"
        :theme="getThemeColor(index)"
        :now="now"
        :is-admin="userIsAdmin"
        :is-sending="false"
        :is-on-user-agenda="hasDiscussionGroupInAgenda(activity)"
        @add-to-agenda="addDiscussionGroupToAgenda"
        @remove-from-agenda="removeDiscussionGroupFromAgenda"
        @enter-discussion-group="goToDiscussionGroup"
      />
    </div>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import LayoutUtil from '@/helpers/layout/layout.helper';
import {
  ADD_CONFERENCE_TO_AGENDA,
  ADD_DISCUSSION_GROUP_TO_AGENDA,
  AGENDA_MODULE,
  GET_AGENDA,
  REMOVE_CONFERENCE_FROM_AGENDA,
  REMOVE_DISCUSSION_GROUP_FROM_AGENDA,
} from '@/stores/umanize-app/actions/agenda/agenda.actions';
import ConferenceDetail from '@/components/conference-detail/ConferenceDetail.vue';
import DiscussionGroupDetail from '@/components/discussion-group-detail/DiscussionGroupDetail.vue';
import { navigateToDiscussionGroup } from '@/navigation';

export default {
  name: 'ActivityList',
  props: ['activities', 'user', 'event'],
  components: {
    ConferenceDetail,
    DiscussionGroupDetail,
  },
  data: () => ({
    timer: null,
    now: DateTime.local().toISO(),
  }),
  computed: {
    ...mapGetters(AGENDA_MODULE, [
      'agenda',
      'isConferenceOnAgenda',
      'isDiscussionGroupOnAgenda',
      'isSending',
    ]),
    userIsAdmin() {
      return this.user.isAdmin;
    },
  },
  methods: {
    ...mapActions(AGENDA_MODULE, [
      GET_AGENDA,
      ADD_CONFERENCE_TO_AGENDA,
      ADD_DISCUSSION_GROUP_TO_AGENDA,
      REMOVE_CONFERENCE_FROM_AGENDA,
      REMOVE_DISCUSSION_GROUP_FROM_AGENDA,
    ]),
    getThemeColor(index) {
      return LayoutUtil.getThemeColor(index);
    },
    hasConferenceInAgenda(conference) {
      return this.isConferenceOnAgenda(conference);
    },
    hasDiscussionGroupInAgenda(conference) {
      return this.isDiscussionGroupOnAgenda(conference);
    },
    addConferenceToAgenda(conference) {
      this[ADD_CONFERENCE_TO_AGENDA](conference);
    },
    addDiscussionGroupToAgenda(discussionGroup) {
      this[ADD_DISCUSSION_GROUP_TO_AGENDA](discussionGroup);
    },
    removeConferenceFromAgenda(conference) {
      this[REMOVE_CONFERENCE_FROM_AGENDA](conference);
    },
    removeDiscussionGroupFromAgenda(discussionGroup) {
      this[REMOVE_DISCUSSION_GROUP_FROM_AGENDA](discussionGroup);
    },
    participate(conference) {
      this.$router.push({
        name: 'Conference',
        params: {
          eventId: conference.eventId,
          conferenceId: conference.id,
        },
      });
    },
    goToDiscussionGroup(discussionGroup) {
      navigateToDiscussionGroup(this.$router)({
        discussionGroupId: discussionGroup.id,
        eventId: discussionGroup.eventId,
      });
    },
  },
  mounted() {
    this[GET_AGENDA](this.event.id);
  },
  created() {
    this.timer = window.setInterval(() => {
      this.now = DateTime.local().toISO();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
