import { DateTime } from 'luxon';
import { Conference, DiscussionGroup } from '@/models';

export default abstract class ActivityUtil {
  public static buildActivities({
    conferences,
    discussionGroups,
  }: {
    conferences: Conference[];
    discussionGroups: DiscussionGroup[];
  }) {
    let nextActivities = [];
    if (conferences) {
      nextActivities = this.addToNextActivities({
        objectType: 'conference',
        objectArray: conferences,
        nextActivities,
      });
    }

    if (discussionGroups) {
      nextActivities = this.addToNextActivities({
        objectType: 'discussionGroup',
        objectArray: discussionGroups,
        nextActivities,
      });
    }

    return nextActivities.sort(
      (a, b) => DateTime.fromISO(a.startTime).valueOf() - DateTime.fromISO(b.startTime).valueOf(),
    );
  }

  private static addToNextActivities({ objectType, objectArray, nextActivities }) {
    return [
      ...nextActivities,
      ...objectArray.map((object) => ({
        type: objectType,
        ...object,
      })),
    ];
  }
}
